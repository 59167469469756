import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RingLoader from "react-spinners/RingLoader";

const Header = lazy(() => import('./Sections/Header'));
const Features = lazy(() => import('./Sections/Features'));
const ExtraFeatures = lazy(() => import('./Sections/ExtraFeatures'));
const Pricing = lazy(() => import('./Sections/Pricing'));
const HowItWorks = lazy(() => import('./Sections/HowItWorks'));
const Downloads = lazy(() => import('./Sections/Downloads'));
const Faq = lazy(() => import('./Sections/Faq'));
const LearnMore = lazy(() => import('./Sections/LearnMore'));
const Footer = lazy(() => import('./Sections/Footer'));
const ScrollButton = lazy(() => import('./Components/ScrollButton'));
const GettingStarted = lazy(() => import('./Sections/GettingStarted'));

export default function App() {
  const isDesktop = window.innerWidth > 767;
  const renderLoader = () =>
    <div className="centered">
      <RingLoader size={150} color='#fff' />
    </div>;

  return (
    <Router>
      <Suspense fallback={renderLoader()}>

        <Header />
        <Features />
        <ExtraFeatures />
        <HowItWorks />
        <GettingStarted />
        <Downloads />
        <Pricing />
        <Faq />
        <LearnMore />
        <Footer />
        {isDesktop && <ScrollButton />}
      </Suspense>
    </Router>
  );
}